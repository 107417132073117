import { useContext, useState, Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import { weekDays } from "utils/constants/onboardingStep";
import MyCalendar from "components/calendar";
import { isPresent } from "utils/helpers/array";
import userStore from "mobXstore/userStore";
import {
  faXmark,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toJS } from "mobx";
import { useEarliestDate } from "utils/hooks/useEarliestDate";

function ChangeEnabledDays({ visible, setModal, updatingMeal, disableButton }) {
  const { mealManagementStore } = useContext(MobXProviderContext);

  const [warning, setWarning] = useState(false);

  const store = useContext(MobXProviderContext);
  const onboardingStore = toJS(store?.onboardingStore);
  const {
    selectedMealArray,
    enable_meal_delivery,
    recieving_days,
    isNotSureSelected,
    default_date,
    meal_allowance_value,
    meal_allowance_type,
  } = onboardingStore;
  const mealPlan = mealManagementStore?.mealPlan;
  const locationID = userStore?.locationID;

  const [defaultDate, setDefaultDate] = useState(default_date || "");
  const [selectDateHover, setSelectDateHover] = useState(false);
  const [mealDeliveryOption, setMealDeliveryOption] = useState(
    enable_meal_delivery || "every week"
  );
  const [mealRecievingDays, setMealRecievingDays] = useState(
    recieving_days || []
  );
  const [mealStartDate, setMealStartDate] = useState(
    isNotSureSelected
      ? "I am not sure yet"
      : default_date !== ""
      ? "select date"
      : ""
  );
  const [mealAllowanceType, setMealAllowanceType] = useState(
    meal_allowance_type || ""
  );
  const [mealAllowanceValue, setMealAllowanceValue] = useState(
    meal_allowance_value || 0
  );
  const [showCalendar, setShowCalendar] = useState(
    mealStartDate !== "" &&
      mealStartDate?.toLowerCase() !== "i am not sure yet" &&
      enable_meal_delivery?.toLowerCase() === "every week"
  );
  const calendarRef = useRef(null);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonthIndex = new Date().getMonth();
  const nextMonthIndex = (currentMonthIndex + 1) % 12;
  const nextNextMonthIndex = (currentMonthIndex + 2) % 12;

  const currentMonthName = monthNames[currentMonthIndex];
  const nextMonthName = monthNames[nextMonthIndex];
  const nextNextMonthName = monthNames[nextNextMonthIndex];

  const initialMealSelectedDates = [
    { [currentMonthName]: [] },
    { [nextMonthName]: [] },
    { [nextNextMonthName]: [] },
  ];

  const [mealSelectedDates, setMealSelectedDates] = useState(
    onboardingStore.recieving_dates || initialMealSelectedDates
  );

  let [
    mealSelectedDatesMonth1,
    mealSelectedDatesMonth2,
    mealSelectedDatesMonth3,
  ] = mealSelectedDates;

  let { earliestDate } = useEarliestDate();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    if (mealAllowanceValue === 0 && mealAllowanceType === "daily") {
      handleMealAllowance(20);
    } else if (mealAllowanceValue === 0 && mealAllowanceType === "weekly") {
      handleMealAllowance(100);
    }
  }, [mealAllowanceType, mealAllowanceValue]);

  const handleMealAllowance = (value) => {
    setMealAllowanceValue(value);
  };

  useEffect(() => {
    let isEveryWeekSelected = false;
    let selectedEarliestDate = earliestDate(mealSelectedDates);

    if (mealDeliveryOption?.toLowerCase() == "i will set my own dates") {
      isEveryWeekSelected = false;
    } else if (mealDeliveryOption?.toLowerCase() == "every week") {
      isEveryWeekSelected = true;
    }
    store.onboardingStore.handleMealSeriesAndSubsidiesData(
      isEveryWeekSelected ? mealRecievingDays : [],
      isEveryWeekSelected
        ? mealStartDate == "select date"
          ? defaultDate
          : mealStartDate
        : selectedEarliestDate,
      !isEveryWeekSelected ? mealSelectedDates : [],
      mealAllowanceType,
      mealAllowanceValue,
      mealDeliveryOption
    );
  }, [
    onboardingStore,
    mealRecievingDays,
    mealStartDate,
    mealSelectedDates,
    mealAllowanceType,
    mealAllowanceValue,
    mealDeliveryOption,
    earliestDate,
    defaultDate,
  ]);

  const handleRecievingDaysClick = (value) => {
    if (mealRecievingDays.includes(value)) {
      setMealRecievingDays((prevDays) =>
        prevDays.filter((day) => day !== value)
      );
    } else {
      setMealRecievingDays((prevDays) => [...prevDays, value]);
    }
  };

  const onSavePress = () => {
    if (!warning) {
      setWarning(true);
    } else if (mealDeliveryOption === "every week")
      mealManagementStore
        .updateMealPlan(locationID, mealPlan?.id, {
          meal_series: {
            days: mealRecievingDays?.map((day) => day?.toLowerCase()),
          },
        })
        .then(() => setModal(false));
    else if (isPresent(mealSelectedDates)) {
      let datesToAdd = [];
      mealSelectedDates?.forEach((date) => {
        datesToAdd = [...datesToAdd, ...Object.values(date)[0]];
      });

      mealManagementStore
        .updateMealPlan(locationID, mealPlan?.id, {
          meal_series: {
            dates_to_add: datesToAdd,
          },
        })
        .then(() => setModal(false));
    }
  };

  const handleMultipleDateChange = (newDate) => {
    const updatedMonthsArray = Array.from(mealSelectedDates, (month) => ({
      ...month,
    }));

    if (newDate?.length > 0) {
      let targetIndex = 0;
      const selectedMonthIndex = newDate[0]?.month.number - 1;

      if (selectedMonthIndex === currentMonthIndex) {
        targetIndex = 0;
      } else if (selectedMonthIndex === nextMonthIndex) {
        targetIndex = 1;
      } else if (selectedMonthIndex === nextNextMonthIndex) {
        targetIndex = 2;
      }

      const selectedMonthName = monthNames[selectedMonthIndex];

      const monthIndex = updatedMonthsArray?.findIndex(
        (month) => month && Object.keys(month)[0] === selectedMonthName
      );

      if (monthIndex !== -1) {
        updatedMonthsArray[monthIndex][selectedMonthName] = newDate?.map(
          (date) => `${date.day}-${date.month.number}-${date.year}`
        );
      } else {
        updatedMonthsArray[targetIndex] = {
          [selectedMonthName]: newDate?.map(
            (date) => `${date.day}-${date.month.number}-${date.year}`
          ),
        };
      }

      setMealSelectedDates(updatedMonthsArray);
    }
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {warning ? (
                <Dialog.Panel className="w-[696px] min-h-[276px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all overflow-visible">
                  <div className="flex justify-end items-center">
                    <button
                      type="button"
                      // onClick={closeModal}
                      className="btn-style"
                    >
                      <FontAwesomeIcon
                        style={{ width: 16, height: 26 }}
                        icon={faXmark}
                        size="2x"
                        color="#BBC0C5"
                      />
                    </button>
                  </div>

                  <div className="flex flex-col mt-7 justify-center items-center">
                    <FontAwesomeIcon
                      style={{ width: 56, height: 56 }}
                      icon={faTriangleExclamation}
                      size="2x"
                      color="#BBC0C5"
                    />
                    <Dialog.Title
                      as="h3"
                      style={{
                        fontSize: 24,
                      }}
                      className="font-inter-semibold text-center leading-6 text-[#2D3958] mt-[12px]"
                    >
                      Are you sure you want to make these changes?
                    </Dialog.Title>

                    <small className="text-[#2D3958] text-center mt-5">
                      {updatingMeal
                        ? "The changes requested will be applied to this meal only, without affecting other meals in the series. If you wish to update the entire meal series, please navigate to the Meal Management section."
                        : "The changes requested will be applied to the entire meal series, affecting all future meals in the series."}
                    </small>
                  </div>

                  <div
                    className={classNames({
                      "flex justify-center items-center mt-5": true,
                    })}
                  >
                    <div className="flex">
                      <button
                        className="btn-outline btn-style"
                        onClick={() => setModal(false)}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn-purple btn-style px-6 ml-3"
                        onClick={onSavePress}
                      >
                        Yes, request changes
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              ) : (
                <Dialog.Panel className="w-[696px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                  <div className="d-row justify-between items-center">
                    <h2 className="text-2xl font-inter-bold">Meal Schedule</h2>
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <div className="mb-0.5 w-full xs:w-full-215 mt-[24px]">
                    <div className="lg:inline-flex md:inline-flex">
                      <div
                        className={`lg:mr-3 md:mr-3 mt-4 lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                          mealDeliveryOption === "every week"
                            ? "bg-indigo-500 text-white "
                            : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                        } rounded-3xl justify-center gap-2 flex`}
                        onClick={() => setMealDeliveryOption("every week")}
                      >
                        Every week
                      </div>

                      <div
                        className={`lg:mr-3 md:mr-3 mt-4 lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                          mealDeliveryOption === "i will set my own dates"
                            ? "bg-indigo-500 text-white "
                            : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                        } rounded-3xl justify-center gap-2 flex`}
                        onClick={() =>
                          setMealDeliveryOption("i will set my own dates")
                        }
                      >
                        I will set my own dates
                      </div>
                    </div>
                  </div>

                  {mealDeliveryOption === "every week" && (
                    <>
                      <small className="input-label mt-[24px]">
                        Select dates
                      </small>
                      <div className="mb-0.5 w-full xs:w-full-215 mt-[8px]">
                        <div className="lg:inline-flex md:inline-flex">
                          {weekDays.map((value, index) => (
                            <div
                              key={index}
                              className={`lg:mr-3 md:mr-3 mt-4 lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                                mealRecievingDays.some((day) => day === value)
                                  ? "bg-indigo-500 text-white "
                                  : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                              } rounded-md justify-center gap-2 flex`}
                              onClick={() => handleRecievingDaysClick(value)}
                            >
                              {value?.slice(0, 3)}
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}

                  {mealDeliveryOption === "i will set my own dates" && (
                    <strong className="mt-[24px] font-inter-semibold text-[16px] mb-[12px]">
                      Pick your dates
                    </strong>
                  )}

                  {mealDeliveryOption === "i will set my own dates" && (
                    <div className="flex inline-flex gap-5 overflow-x-auto ">
                      <MyCalendar
                        highlightToday={false}
                        disableMonthYearPicker={true}
                        isMultiple={true}
                        showButtons={false}
                        handleMultipleDates={handleMultipleDateChange}
                        value={1}
                        multipleValues={mealSelectedDatesMonth1}
                        isOwnDates={true}
                      />

                      <MyCalendar
                        highlightToday={false}
                        disableMonthYearPicker={true}
                        isMultiple={true}
                        showButtons={false}
                        handleMultipleDates={handleMultipleDateChange}
                        value={2}
                        multipleValues={mealSelectedDatesMonth2}
                        isOwnDates={true}
                      />

                      <MyCalendar
                        highlightToday={false}
                        disableMonthYearPicker={true}
                        isMultiple={true}
                        showButtons={false}
                        handleMultipleDates={handleMultipleDateChange}
                        value={3}
                        multipleValues={mealSelectedDatesMonth3}
                        isOwnDates={true}
                      />
                    </div>
                  )}

                  <div className="d-row w-full items-center mt-[24px] justify-center">
                    <button
                      className="btn-light btn-style py-3 px-5"
                      onClick={() => setModal(false)}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn-purple btn-style py-3 px-6 ml-3"
                      onClick={onSavePress}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ChangeEnabledDays;
