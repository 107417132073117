import { useContext, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import userStore from "mobXstore/userStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function ChangeExpectedOrders({
  visible,
  setModal,
  updatingMeal = false,
  meal,
}) {
  const [expectedBags, changeExpectedBags] = useState(
    updatingMeal ? meal?.attributes?.expected_bags : ""
  );
  const [warning, setWarning] = useState(false);

  const { mealManagementStore } = useContext(MobXProviderContext);
  const { homeStore } = useContext(MobXProviderContext);
  const { userStore } = useContext(MobXProviderContext);

  const mealPlan = mealManagementStore.mealPlan;

  const { locationID } = userStore;

  const onSavePress = () => {
    if (!warning) {
      setWarning(true);
    } else if (updatingMeal) {
      homeStore
        ?.updateMeal(
          meal?.id,
          {
            meal: { expected_bags: expectedBags },
          },
          locationID
        )
        .then(() => setModal(false));
    } else
      mealManagementStore
        .updateMealPlan(locationID, mealPlan?.id, {
          meal_series: {
            expected_bags: expectedBags,
          },
        })
        .then(() => setModal(false));
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {warning ? (
                <Dialog.Panel className="w-[696px] min-h-[276px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all overflow-visible">
                  <div className="flex justify-end items-center">
                    <button
                      type="button"
                      // onClick={closeModal}
                      className="btn-style"
                    >
                      <FontAwesomeIcon
                        style={{ width: 16, height: 26 }}
                        icon={faXmark}
                        size="2x"
                        color="#BBC0C5"
                      />
                    </button>
                  </div>

                  <div className="flex flex-col mt-7 justify-center items-center">
                    <FontAwesomeIcon
                      style={{ width: 56, height: 56 }}
                      icon={faTriangleExclamation}
                      size="2x"
                      color="#BBC0C5"
                    />
                    <Dialog.Title
                      as="h3"
                      style={{
                        fontSize: 24,
                      }}
                      className="font-inter-semibold text-center leading-6 text-[#2D3958] mt-[12px]"
                    >
                      Are you sure you want to make these changes?
                    </Dialog.Title>

                    <small className="text-[#2D3958] font-inter-regular text-[16px] text-center mt-5">
                      {updatingMeal
                        ? "The changes requested will be applied to this meal only, without affecting other meals in the series. If you wish to update the entire meal series, please navigate to the Meal Management section."
                        : "The changes requested will be applied to the entire meal series, affecting all future meals in the series."}
                    </small>
                  </div>

                  <div
                    className={classNames({
                      "flex justify-center items-center mt-5": true,
                    })}
                  >
                    <div className="flex">
                      <button
                        className="btn-outline btn-style"
                        onClick={() => setModal(false)}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn-purple btn-style px-6 ml-3"
                        onClick={onSavePress}
                      >
                        Yes, request changes
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              ) : (
                <Dialog.Panel className="w-[696px] h-[276px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                  <div className="d-row justify-between items-center">
                    <h2 className="text-2xl font-inter-bold">Expected order</h2>
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <span className="font-inter-regular text-dark-gray text-[14px]">
                    The number of people expected to place an order
                  </span>

                  <input
                    autoFocus
                    required
                    className="flex w-full bg-background text-dark-gray focus:outline-none rounded-l-md text-md py-[9px] p-[19px]"
                    value={expectedBags}
                    onChange={(e) => changeExpectedBags(e.target.value)}
                    type="number"
                  />

                  <div className="d-row w-full items-center mt-[24px] justify-center">
                    <button
                      className="btn-light btn-style py-3 px-5"
                      onClick={() => setModal(false)}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn-purple btn-style py-3 px-6 ml-3"
                      onClick={onSavePress}
                    >
                      Request
                    </button>
                  </div>
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ChangeExpectedOrders;
