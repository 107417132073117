import { makeAutoObservable, observable, action, toJS } from "mobx";
import { client } from "../store";
import api from "store/api";
import userStore from "./userStore";
import qs from "qs";
import { generateId } from "../utils/helpers/validation";
import { errorHandler } from "utils/middlewares/errorHandler";

class HomeStore {
  mealPlans = [];
  mealPlan = {};
  activeMeals = [];
  locationEmployees = [];
  teams = [];
  groupOrders = [];
  groupOrder = null;
  cuisineTypes = [];
  dietaryPreferences = [];
  meals = [];
  mealsByWeek = [];
  cateringOrders = [];
  allEmployees = [];
  employeesWithOrderInfo = [];
  mealTeams = [];
  locationAddresses = [];

  constructor() {
    makeAutoObservable(this, {
      mealPlans: observable,
      mealPlan: observable,
      locationEmployees: observable,
      teams: observable,
      groupOrders: observable,
      groupOrder: observable,
      dietaryPreferences: observable,
      cuisineTypes: observable,
      meals: observable,
      mealsByWeek: observable,
      cateringOrders: observable,
      activeMeals: observable,
      allEmployees: observable,
      employeesWithOrderInfo: observable,
      mealTeams: observable,
      locationAddresses: observable,

      fetchMealPlans: action,
      fetchMealPlan: action,
    });
  }

  async fetchActiveMeals(params, locationId) {
    let _params = qs.stringify(params);
    try {
      userStore.setLoader(true);

      const data = await client().get(
        `${api.meals()}?location_id=${locationId}&${_params}`
      );
      this.activeMeals = data?.data?.data;
      userStore.setLoader(false);
    } catch (error) {
      userStore.setLoader(false);
      errorHandler(error?.response);
    }
  }

  async fetchMealPlans(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.mealPlans()}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.mealPlans = response?.data?.data;
    } catch (error) {
      userStore.setLoader(false);
      errorHandler(error?.response);
    }
  }

  async fetchMealPlan(id) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.mealPlans()}/${id}?location_id=${1}`
      );
      userStore.setLoader(false);
      this.mealPlan = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateMealPlan(mealPlanId, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.mealPlans()}/${mealPlanId}?location_id=${1}`,
        payload
      );
      userStore.setLoader(false);
      this.mealPlan = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async sendDeliveryTimeRequest(mealPlanId, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.mealPlans()}/${mealPlanId}?location_id=${1}`,
        payload
      );
      userStore.setLoader(false);
      this.mealPlan = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async fetchTeams(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.teams()}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.teams = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async fetchGroupOrders(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.groupOrders()}?location_id=${locationId}&type=group`
      );
      userStore.setLoader(false);
      this.groupOrders = response?.data?.data;
    } catch (error) {}
  }

  async fetchCateringOrders(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.groupOrders()}?location_id=${locationId}&type=odc`
      );

      userStore.setLoader(false);
      this.cateringOrders = response?.data?.data;
    } catch (error) {}
  }

  async fetchCateringOrder(orderSlug, locationId) {
    const response = await client().get(
      `${api.odcOrder()}/${orderSlug}?location_id=${locationId}`
    );
    return response?.data?.data;
  }

  async cancelOrder(orderId) {
    const response = await client().delete(`${api.odcOrder()}/${orderId}`);

    return response?.data?.data;
  }

  async fetchGroupOrderDetails(id) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.groupOrders()}/${id}?location_id=${1}`
      );
      userStore.setLoader(false);
      this.groupOrder = response?.data?.data;
      return response;
    } catch (error) {}
  }

  async updateUncheckedOutOrder(id, slug, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.uncheckedOutOrder()}/${id}?slug=${slug}`,
        payload
      );
      userStore.setLoader(false);
      return response;
    } catch (error) {}
  }

  async fetchEmployees(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.employees()}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.locationEmployees = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async bulkSubsidyUpdate(mealPlanId, payload, bulkUsers) {
    let userIds = qs.stringify(
      {
        ids: bulkUsers,
      },
      { arrayFormat: "brackets" }
    );

    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.bulkSubsidyUpdate()}/?location_id=${1}&${userIds}`,
        payload
      );
      userStore.setLoader(false);
      this.fetchMealPlan(mealPlanId);
    } catch (error) {
      throw error;
    }
  }

  async fetchCuisineTypesData() {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.adminPanelDietaryPreference()}?location_id=${1}`
      );

      const cuisineArray = response.data?.cuisines;
      const preferencesArray = response.data?.dietary_preferences;

      this.cuisineTypes = cuisineArray;
      this.dietaryPreferences = preferencesArray;
      userStore.setLoader(false);
    } catch (error) {
      throw error;
    }
  }

  async fetchActiveMealsForWeek(params, locationId) {
    // let _params = qs.stringify(params);
    // try {
    //   userStore.setLoader(true);
    //   let sortedData = [];
    //   const data = await client().get(
    //     `${api.meals()}?location_id=${locationId}&${_params}`
    //   );
    //   this.meals = data?.data;

    //   data?.data?.data?.map(
    //     ({
    //       attributes: {
    //         id,
    //         name,
    //         meal_date,
    //         cutoff,
    //         expected_bags,
    //         meal_series_id,
    //         menus,
    //         shipments,
    //         target_time,
    //         meal_series_cutoff,
    //         meal_series_expected_bags,
    //         meal_series_target_time,
    //         disabled,
    //         restaurant_slots,
    //         manual_restaurant_slots,
    //         published,
    //         meal_series_manual_restaurant_slots,
    //         meal_series_slots,
    //         meal_series_restaurant_slots,
    //         slots,
    //         new_client,
    //         active,
    //       },
    //     }) => {
    //       let foundIndex = sortedData.findIndex((x) => x.date === meal_date);

    //       if (foundIndex > -1) {
    //         let meal = sortedData[foundIndex].meal;
    //         sortedData[foundIndex].meal = [
    //           ...meal,
    //           {
    //             id,
    //             tempId: id ? id : generateId(),
    //             name,
    //             meal_date,
    //             cutoff,
    //             expected_bags,
    //             meal_series_id,
    //             menus,
    //             shipments,
    //             target_time,
    //             meal_series_cutoff,
    //             meal_series_expected_bags,
    //             meal_series_target_time,
    //             disabled,
    //             restaurant_slots,
    //             manual_restaurant_slots,
    //             published,
    //             meal_series_manual_restaurant_slots,
    //             meal_series_slots,
    //             meal_series_restaurant_slots,
    //             slots,
    //             new_client,
    //             active,
    //           },
    //         ];
    //       } else {
    //         sortedData.push({
    //           date: meal_date,
    //           meal: [
    //             {
    //               id,
    //               tempId: id ? id : generateId(),
    //               name,
    //               meal_date,
    //               cutoff,
    //               expected_bags,
    //               meal_series_id,
    //               menus,
    //               shipments,
    //               target_time,
    //               meal_series_cutoff,
    //               meal_series_expected_bags,
    //               meal_series_target_time,
    //               disabled,
    //               restaurant_slots,
    //               manual_restaurant_slots,
    //               published,
    //               meal_series_manual_restaurant_slots,
    //               meal_series_slots,
    //               meal_series_restaurant_slots,
    //               slots,
    //               new_client,
    //               active,
    //             },
    //           ],
    //         });
    //       }
    //     }
    //   );

    //   this.mealsByWeek = sortedData.sort(function (a, b) {
    //     return new Date(a.date) - new Date(b.date);
    //   });

    //   // this.setUpdatedResponse(
    //   //   sortedData.sort(function (a, b) {
    //   //     return new Date(a.date) - new Date(b.date);
    //   //   })
    //   // );
    //   userStore.setLoader(false);
    //   return data?.data?.data;
    // } catch (error) {
    //   userStore.setLoader(false);
    //   errorHandler(error?.response);
    // }

    let _params = qs.stringify(params);
    try {
      userStore.setLoader(true);
      let sortedData = [];
      const data = await client().get(
        `${api.meals()}?location_id=${locationId}&${_params}`
      );
      this.meals = data?.data;

      // Step 1: Parse start_date and end_date from params and create an array of all dates in between
      const startDate = new Date(params.start_date);
      const endDate = new Date(params.end_date);
      const allDates = [];

      for (
        let d = new Date(startDate);
        d <= endDate;
        d.setDate(d.getDate() + 1)
      ) {
        allDates.push(new Date(d).toISOString().split("T")[0]); // Store dates in YYYY-MM-DD format
      }

      // Step 2: Process meal data
      data?.data?.data?.map(
        ({
          attributes: {
            id,
            name,
            meal_date,
            cutoff,
            expected_bags,
            meal_series_id,
            menus,
            shipments,
            target_time,
            meal_series_cutoff,
            meal_series_expected_bags,
            meal_series_target_time,
            disabled,
            restaurant_slots,
            manual_restaurant_slots,
            published,
            meal_series_manual_restaurant_slots,
            meal_series_slots,
            meal_series_restaurant_slots,
            slots,
            new_client,
            active,
            service,
            under_review,
            tzinfo,
          },
        }) => {
          let foundIndex = sortedData.findIndex((x) => x.date === meal_date);

          if (foundIndex > -1) {
            let meal = sortedData[foundIndex].meal;
            sortedData[foundIndex].meal = [
              ...meal,
              {
                id,
                tempId: id ? id : generateId(),
                name,
                meal_date,
                cutoff,
                expected_bags,
                meal_series_id,
                menus,
                shipments,
                target_time,
                meal_series_cutoff,
                meal_series_expected_bags,
                meal_series_target_time,
                disabled,
                restaurant_slots,
                manual_restaurant_slots,
                published,
                meal_series_manual_restaurant_slots,
                meal_series_slots,
                meal_series_restaurant_slots,
                slots,
                new_client,
                active,
                service,
                under_review,
                tzinfo,
              },
            ];
          } else {
            sortedData.push({
              date: meal_date,
              meal: [
                {
                  id,
                  tempId: id ? id : generateId(),
                  name,
                  meal_date,
                  cutoff,
                  expected_bags,
                  meal_series_id,
                  menus,
                  shipments,
                  target_time,
                  meal_series_cutoff,
                  meal_series_expected_bags,
                  meal_series_target_time,
                  disabled,
                  restaurant_slots,
                  manual_restaurant_slots,
                  published,
                  meal_series_manual_restaurant_slots,
                  meal_series_slots,
                  meal_series_restaurant_slots,
                  slots,
                  new_client,
                  active,
                  service,
                  under_review,
                  tzinfo,
                },
              ],
            });
          }
        }
      );

      // Step 3: Ensure every day has a corresponding entry
      allDates.forEach((date) => {
        if (!sortedData.find((item) => item.date === date)) {
          sortedData.push({ date, meal: [] });
        }
      });

      // Step 4: Sort the final data by date
      this.mealsByWeek = sortedData.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });

      userStore.setLoader(false);
      return this.mealsByWeek;
    } catch (error) {
      userStore.setLoader(false);
      errorHandler(error?.response);
    }
  }

  async fetchGroupOrderStatus(groupOrderId, locationId) {
    const response = await client().get(
      `${api.groupOrder()}/${groupOrderId}/status?location_id=${locationId}`
    );
    return response?.data;
  }

  async fetchMealDetail(mealId, locationId) {
    const response = await client().get(
      `${api.meals()}/${mealId}?location_id=${locationId}`
    );

    const employeeRespose = await client().get(
      `${api.employees()}?location_id=${locationId}`
    );

    this.allEmployees = employeeRespose?.data?.data;

    return response?.data?.data;
  }

  async deleteUncateringOrder(orderId, locationId) {
    userStore.setLoader(true);

    const response = await client().delete(
      `${api.uncateringOrder()}/${orderId}?location_id=${locationId}`
    );

    userStore.setLoader(false);

    return response?.data;
  }

  //home page actions

  async fetchGroupOrder(groupOrderId, locationId) {
    const response = await client().get(
      `${api.groupOrder()}/${groupOrderId}?location_id=${locationId}`
    );
    return response?.data?.data;
  }

  async updateGroupOrder(groupOrderId, payload, params) {
    let _params = qs.stringify(params);

    const response = await client().patch(
      `${api.groupOrder()}/${groupOrderId}?${_params}`,
      payload
    );
    return response?.data?.data;
  }

  async groupOrderCheckout(groupOrderId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.groupOrder()}/${groupOrderId}/checkout?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async fetchOrderDeadline(menuId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.groupOrderDeadline()}/${menuId}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response, null, "Restaurant minimum not met");
          return error;
        })
      );
  }

  async generatePDF(orderSlug) {
    userStore.setLoader(true);

    return client()
      .get(`${api.odcOrder()}/${orderSlug}.pdf`, { responseType: "blob" })
      .then(
        action("fetchSuccess", (response) => {
          let newBlob = new Blob([response.data], { type: "application/pdf" });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.download = `${orderSlug}.pdf`;
          link.click();
          setTimeout(function () {
            window.URL.revokeObjectURL(url);
          }, 100);

          userStore.setLoader(false);
          // this.setErrorAlert(true, {
          //   title: "Invoice generated successfully",
          // });
        }),
        action("fetchError", (error) => {
          // this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async fetchUncateringShipmentDetail(shipmentId, locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.groupOrders()}/${shipmentId}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      return response?.data?.data;
    } catch (error) {}
  }

  async fetchUncateringOrderDetail(bagId, locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.uncateringOrder()}/${bagId}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      return response?.data?.data;
    } catch (error) {}
  }

  async fetchUsersWithOrderDetails(params) {
    let paramsString = qs.stringify(params);

    try {
      userStore.setLoader(true);
      const response = await client().get(`${api.employees()}?${paramsString}`);

      this.employeesWithOrderInfo = response.data?.data;
      userStore.setLoader(false);
      return response?.data?.data;
    } catch (error) {}
  }

  async fetchTeamsForMeal(params) {
    let paramsString = qs.stringify(params);

    try {
      userStore.setLoader(true);
      const response = await client().get(`${api.mealTeams()}?${paramsString}`);

      this.mealTeams = response.data?.data?.map((team) => {
        return { label: team?.attributes?.name, value: team?.id };
      });
      userStore.setLoader(false);
      return response?.data?.data;
    } catch (error) {}
  }

  async updateMeal(mealId, payload, locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.meals()}/${mealId}?location_id=${locationId}`,
        payload
      );
      userStore.setLoader(false);
    } catch (error) {
      throw error;
    }
  }

  async getAddresses(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.locationAddresses()}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.locationAddresses = response?.data?.data;
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteTeamMeal(teamMealId, params) {
    let paramsString = qs.stringify(params);

    try {
      userStore.setLoader(true);
      const response = await client().delete(
        `${api.teamMeals()}/${teamMealId}?${paramsString}`
      );
      userStore.setLoader(false);
      return response?.data?.data;
    } catch (error) {
      userStore.setLoader(false);
      errorHandler(error?.response);
    }
  }

  async deleteEmployeeMeal(params) {
    const queryString = qs.stringify(params, { arrayFormat: "brackets" });

    try {
      userStore.setLoader(true);
      const response = await client().delete(
        `${api.employeeMeals()}?${queryString}`
      );
      userStore.setLoader(false);
      return response?.data?.data;
    } catch (error) {
      userStore.setLoader(false);
      errorHandler(error?.response);
    }
  }

  async updateEmployeeSubsidyForMeal(params, payload) {
    let paramsString = qs.stringify(params);

    try {
      userStore.setLoader(true);
      const response = await client().patch(
        `${api.employeeSubsidies()}?${paramsString}`,
        payload
      );
      userStore.setLoader(false);
    } catch (error) {
      throw error;
    }
  }
}

const homeStore = new HomeStore();
export default homeStore;
