import { useContext, useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import classNames from "classnames";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import userStore from "mobXstore/userStore";

function ChangeSubsidy({
  visible,
  setModal,
  bulkUserUpdate = false,
  bulkUsers,
  updatingMeal = false,
  meal,
  updatingUserSubsidiesForMeal,
  onComplete,
}) {
  const [subsidyType, setSubsidyType] = useState("daily");
  const [weeklySubsidy, setWeeklySubsidy] = useState(0);
  const [dailySubsidy, setDailySubsidy] = useState({});
  const [overPayment, setOverPayment] = useState(false);
  const { mealManagementStore } = useContext(MobXProviderContext);
  const mealPlan = mealManagementStore.mealPlan;
  const { homeStore } = useContext(MobXProviderContext);
  const { userStore } = useContext(MobXProviderContext);
  const { locationID } = userStore;

  useEffect(() => {
    if (!bulkUserUpdate) {
      if (updatingMeal) {
        setDailySubsidy(toJS(meal?.attributes?.daily_subsidy));
        setSubsidyType(meal?.attributes?.subsidy_type ?? "daily");
        setWeeklySubsidy(meal?.attributes?.subsidy);
      } else {
        setDailySubsidy(toJS(mealPlan?.attributes?.daily_subsidy));
        setSubsidyType(mealPlan?.attributes?.subsidy_type ?? "daily");
        setWeeklySubsidy(mealPlan?.attributes?.subsidy);
      }
    } else {
      setDailySubsidy({
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
      });
    }
  }, [mealPlan]);

  const onSavePress = () => {
    if (updatingUserSubsidiesForMeal) {
      homeStore
        .updateEmployeeSubsidyForMeal(
          {
            meal_id: meal?.id,
            location_id: locationID,
          },
          {
            location_employee_ids: bulkUsers?.map((user) => user?.id),
            subsidy_type: subsidyType,
            daily_subsidy: dailySubsidy,
            subsidy: weeklySubsidy,
            employee_pays_by_credit_card: overPayment,
          }
        )
        .then(() => {
          onComplete();
          setModal(false);
        });
    } else if (updatingMeal) {
      homeStore
        .updateMeal(
          meal?.id,
          {
            meal: {
              subsidy_type: subsidyType,
              daily_subsidy: dailySubsidy,
              subsidy: weeklySubsidy,
              employee_pays_by_credit_card: overPayment,
            },
          },
          locationID
        )
        .then(() => setModal(false));
    } else if (bulkUserUpdate) {
      mealManagementStore
        .bulkSubsidyUpdate(locationID, mealPlan?.id, {
          ids: bulkUsers?.map((user) => user?.id),
          employee_meal_plan: {
            subsidy_type: subsidyType,
            daily_subsidy: dailySubsidy,
            subsidy: weeklySubsidy,
            employee_pays_by_credit_card: overPayment,
          },
        })
        .then(() => setModal(false));
    } else
      mealManagementStore
        .updateMealPlan(locationID, mealPlan?.id, {
          meal_series: {
            subsidy_type: subsidyType,
            daily_subsidy: dailySubsidy,
            subsidy: weeklySubsidy,
            employee_pays_by_credit_card: overPayment,
          },
        })
        .then(() => setModal(false));
  };

  const onChangeDaily = (value, day) => {
    let daily = dailySubsidy;

    daily[day] = value;

    setDailySubsidy({ ...daily });
  };

  const setEnableOverpayment = (value) => {
    setOverPayment(value);
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[790px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-row justify-between items-center">
                  <h2 className="text-2xl font-inter-bold">
                    Update default Subsidy
                  </h2>
                  <button
                    onClick={() => setModal(false)}
                    className="text-dark-gray hover:text-yellow-600"
                    aria-label="Close"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <span className="font-inter-regular text-[14px] my-[24px]">
                  Choose frequency
                </span>

                <div className="mb-3">
                  <div className="d-row mb-[11px]">
                    <button
                      className={classNames({
                        "d-row items-center rounded-xl text-catering hover:text-white font-inter-medium mr-[10px]": true,
                        "btn-disabled-mode": subsidyType !== "daily",
                        "btn-blue-accent text-white": subsidyType == "daily",
                      })}
                      onClick={() => {
                        setSubsidyType("daily");
                      }}
                    >
                      Daily
                    </button>

                    <button
                      className={classNames({
                        "d-row items-center rounded-xl text-catering hover:text-white font-inter-medium": true,
                        "btn-disabled-mode": subsidyType !== "weekly",
                        "btn-blue-accent text-white": subsidyType == "weekly",
                      })}
                      onClick={() => {
                        setSubsidyType("weekly");
                      }}
                    >
                      Weekly
                    </button>
                  </div>

                  {subsidyType === "daily" ? (
                    <div className="grid grid-cols-7 gap-4 rounded-md py-[12px] bg-light-gray px-[10px]">
                      {Object.keys(dailySubsidy)?.map((day) => (
                        <div key={day} className="text-center rounded">
                          <span className="text-sm font-inter-medium text-gray-700 capitalize">
                            {day.slice(0, 3)}
                          </span>

                          <div className="bg-white d-row px-[16px] py-[7px] mt-[7px] rounded-lg items-center">
                            <small className="text-[16px] font-inter-medium">
                              $
                            </small>
                            <input
                              className="w-full text-[16px] font-inter-medium focus:outline-none h-[40px] text-black"
                              type="number"
                              value={dailySubsidy[day]}
                              onChange={(e) => {
                                // e?.target?.value > -1 &&
                                onChangeDaily(e?.target?.value, day);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="focus:outline-none bg-light-gray w-1/2 py-[9px] px-[19px] rounded-md">
                      <small className="text-[16px] font-inter-medium">$</small>
                      <input
                        autoFocus
                        className="text-[16px] font-inter-medium bg-transparent h-full focus:outline-none text-black"
                        type="number"
                        onChange={(e) =>
                          e?.target?.value > -1 &&
                          setWeeklySubsidy(e.target?.value)
                        }
                        value={weeklySubsidy}
                      />
                    </div>
                  )}
                </div>

                <div className="flex-col justify-start items-start gap-[7px] mt-[24px] flex">
                  <label className="relative inline-flex items-center cursor-pointer gap-2.5">
                    <input
                      type="checkbox"
                      checked={overPayment}
                      className="sr-only peer"
                      onChange={(e) => setEnableOverpayment(e.target.checked)}
                    />

                    <div className=" w-9 h-5 p-0.5 bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:start-[2px] after:bg-white after:w-4 after:h-4 after:shadow after:border after:rounded-full after:transition-all  peer-checked:bg-indigo-500"></div>

                    <span className="text-slate-700 text-sm font-inter-medium leading-none">
                      Overpayment
                    </span>
                  </label>

                  <div className="justify-start items-start gap-1 mt-[14px] inline-flex">
                    {/* <img src={dangerIcon} className="w-3 h-3" alt="DangerIcon" /> */}

                    <div className="lg:w-[428px] md:w-[428px] w-full text-slate-500 text-xs font-inter-normal leading-none">
                      Allow users to use their personal credit card when going
                      over set allowance to change your settings, please contact
                      your hungerhub account admin.
                    </div>
                  </div>
                </div>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={onSavePress}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default observer(ChangeSubsidy);
