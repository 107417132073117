import {
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmbedHTML from "components/shared/embedHTML";
import Tooltip from "components/Tooltip";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { useContext, useReducer, useState, useEffect } from "react";
import dateTime, { checkAndGetTimezone } from "utils/helpers/dateTime";
import { priceFormatter } from "utils/helpers/validation";
import { MobXProviderContext } from "mobx-react";
import { Disclosure } from "@headlessui/react";
import { Transition } from "@headlessui/react";
import ConfirmationModal from "components/ConfirmationModal";
import { changeSpacesToHyphens } from "utils/helpers/restaurants";
import { getOrderStatus } from "utils/helpers/order";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { getLocationEmployeesWithAdmin } from "../../utils/helpers/array";
import { formattedAddres } from "utils/helpers/string";
import { SearchBar } from "components";

function CateringOrderDetail() {
  const initialState = {
    order: null,
    updateEmailModal: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const [cancelOrderAlert, setCancelOrderAlert] = useState(null);
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const { currentUser, defaultLocation } = userStore;
  const navigate = useNavigate();
  const { slug, locationId } = useParams();

  const { isPending, error, data } = useQuery({
    queryKey: ["cateringOrder", locationId, slug],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.mealManagementStore.fetchCateringOrder(slug, locationId);
    },
  });

  const mutation = useMutation({
    mutationFn: () =>
      store.mealManagementStore?.cancelOrder(order?.attributes?.id),
    onSuccess: (data) => {
      setCancelOrderAlert(null);
    },
    onError: (error) => {
      console.error("Error cancelling order:", error);
    },
  });

  const order = data;

  const getOrder = () => {
    // let { slug } = router?.query;
    // store.cartStore
    //   ?.getOrder(slug)
    //   .then((response) => {
    //     store.userStore?.setLoader(false);
    //     if (response?.data) dispatch({ field: "order", value: response?.data });
    //   })
    //   .catch(() => {
    //     store.userStore?.setLoader(false);
    //   });
  };

  useEffect(() => {
    if (currentUser && locationId) {
      const locationEmployees = getLocationEmployeesWithAdmin(currentUser);
      const preSelectedLocation = locationEmployees?.find(
        (employee) => Number(employee.organizationId) === Number(locationId)
      );
      store.userStore?.setdefaultLocation(preSelectedLocation);
    }
  }, []);

  const getTime = () => {
    if (order?.attributes?.status == "completed")
      return order.attributes.completed_at
        ? `${dateTime.localWithUTCoffset(
            order.attributes.completed_at,
            order.attributes.tzinfo,
            "hh:mm a"
          )}  ${checkAndGetTimezone(order.attributes.tzinfo, true)}`
        : "Not available";
    else
      return `${order.attributes.delivery_time} ${checkAndGetTimezone(
        order.attributes.tzinfo,
        true
      )}`;
  };

  const showCancelButton = () => {
    if (order.attributes.status !== "pending") return false;

    if (parseFloat(order.attributes.grand_total).toFixed(2) > 5000)
      return false;

    let dateTime = moment(
      order.attributes.order_date + " " + order.attributes.delivery_time,
      "YYYY-MM-DD hh:mm A"
    );

    let duration = moment.duration(dateTime.diff(moment()));
    let hours = duration.asHours();

    let sortedMinimumOrderPreferences =
      order?.attributes?.minimum_order_preferences?.sort(
        (a, b) => a.minimum_amount - b.minimum_amount
      );

    if (hours < calculateOrderCancellationHours(sortedMinimumOrderPreferences))
      return false;

    return true;
  };

  const calculateOrderCancellationHours = (array) => {
    let targetValue = order?.attributes?.subtotal;

    if (array.length === 0) return 0;

    if (array.length === 1) {
      return array[0]?.required_notice_time;
    }

    if (targetValue > array[array.length - 1].minimum_amount) {
      return array[array.length - 1].required_notice_time;
    }

    for (let i = 0; i < array.length - 1; i++) {
      let currentObj = array[i];
      let nextObj = array[i + 1];

      if (
        targetValue >= currentObj.minimum_amount &&
        targetValue <= nextObj.minimum_amount
      ) {
        return currentObj.required_notice_time;
      }
    }
  };

  const getStatusString = () => {
    switch (order?.attributes?.status) {
      case "completed":
        return "Your order was successfully delivered";
      case "cancelled":
        return "Your order has been cancelled and payment method refunded";
      default:
        return `Your order is ${getOrderStatus(order?.attributes?.status)}`;
    }
  };

  const getETA = () => {
    return `${dateTime.localWithUTCoffset(
      order.attributes.dropoff_task_eta,
      order.attributes.tzinfo,
      "hh:mm a"
    )} ${dateTime.checkAndGetTimezone(order.attributes.tzinfo, true)}`;
  };

  const getETAString = () => {
    if (order?.attributes?.dropoff_task_eta) {
      return `Your estimated time of arrival: ${getETA()}`;
    }
  };

  const getCancellationDeadline = () => {
    return moment(order?.attributes?.cancellation_deadline)
      .utc()
      .format("dddd Do MMMM YYYY - hh:mma");
  };

  const onCancelOrder = () => {
    mutation.mutate();
  };

  const itemInstructionsAndName = (item) => (
    <div className="flex flex-col p-2 rounded-md">
      {item?.attributes?.special_instructions && (
        <div className="flex items-center ">
          <FontAwesomeIcon icon={faComment} size="1x" className="small-icon" />

          <span className="text-sm ml-2">
            Instructions:{" "}
            <span className="font-inter-medium">
              {item?.attributes?.special_instructions}
            </span>
          </span>
        </div>
      )}
    </div>
  );

  const getNameSubstring = (string) => {
    return string?.length > 32
      ? `${string?.substring(0, 32)}...`
      : string?.substring(0, 32);
  };

  const resendEmail = () => {
    store.userStore?.setLoader(true);
    store.userStore
      .resendEmail(order?.attributes?.slug)
      .then((response) => {
        store.userStore?.setLoader(false);

        if (response?.message)
          store.userStore?.setErrorAlert(true, {
            title: response.message,
          });
      })
      .catch(() => store.userStore?.setLoader(false));
  };

  const orderDetails = (
    <>
      <div className="mt-2">
        <div className="flex py-5 px-3 bg-background">
          <div className="text-sm font-inter-medium w-2/12 hidden sm:flex">
            QTY
          </div>

          <div className="text-sm font-inter-medium w-2/12 flex sm:hidden">
            Q-ty
          </div>

          <div className="text-sm font-inter-medium w-8/12 md:w-4/12">Item</div>

          <div className="text-sm font-inter-medium hidden md:flex md:w-4/12"></div>
          <div className="text-sm font-inter-medium w-2/12 text-right">
            Food Total
          </div>

          <div className="text-sm font-inter-medium w-2/12 text-right">
            Name/Label
          </div>
        </div>

        {order?.attributes?.menu_categories?.map((category) => (
          <div className="flex flex-col mt-7" key={category.id}>
            <Disclosure defaultOpen={true}>
              {({ open }) => (
                /* Use the `open` state to conditionally change the direction of an icon. */
                <>
                  <Disclosure.Button>
                    <div className="flex justify-between items-center bg-white px-3 mb-3">
                      <div className="flex">
                        <EmbedHTML
                          text={category?.display_name_with_html}
                          className={
                            "text-header-text-2 text-md mr-2 font-inter-medium"
                          }
                        />

                        <span className="text-md font-inter-light">
                          Total: {category.quantity}
                        </span>
                      </div>

                      <FontAwesomeIcon
                        size="1x"
                        className="icon"
                        icon={open ? faAngleUp : faAngleDown}
                      />
                    </div>
                  </Disclosure.Button>

                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel>
                      {category?.order_items?.map((item) => (
                        <div
                          key={item.id}
                          className={`flex w-full px-3 pt-3 border-border-gray ${
                            category?.order_items?.length > 1 &&
                            "border-t first:border-t-0 py-3"
                          }`}
                        >
                          <div className="text-sm w-2/12">
                            <span>{item.attributes.quantity}</span>
                          </div>

                          <div className="text-sm w-8/12 md:w-4/12">
                            <div className="flex flex-col">
                              <span className="text-[14px]">
                                <EmbedHTML
                                  text={
                                    item?.attributes?.menu_item?.attributes
                                      ?.display_name_with_html
                                  }
                                  className="text-header-text-2 text-[14px] font-inter-medium"
                                />
                              </span>

                              {item?.attributes?.order_item_options?.map(
                                (option) => (
                                  <div className="flex mt-1" key={option.id}>
                                    <span className="d-row text-sm">
                                      <EmbedHTML
                                        isRow
                                        text={
                                          option?.attributes
                                            ?.option_group_display_name_with_html
                                        }
                                        append={": "}
                                        appendClass="mr-1"
                                        className="text-primary-black text-[12px]"
                                      />
                                      <EmbedHTML
                                        text={
                                          option?.attributes
                                            ?.option_display_name_with_html
                                        }
                                        className="text-primary-black font-inter-normal text-[12px]"
                                      />
                                    </span>
                                  </div>
                                )
                              )}

                              <div className="flex md:hidden">
                                {itemInstructionsAndName(item)}
                              </div>
                            </div>
                          </div>

                          <div className="font-inter-normal text-[14px] hidden md:flex md:w-4/12">
                            {itemInstructionsAndName(item)}
                          </div>

                          <div className="flex flex-col w-2/12 text-right">
                            <span className="text-black font-inter-normal text-[14px]">
                              {priceFormatter(item?.attributes?.total_price)}
                            </span>

                            {item?.attributes?.quantity > 1 && (
                              <span
                                className="text-sm"
                                style={{ color: "#8F979F" }}
                              >
                                {priceFormatter(
                                  item?.attributes?.menu_item?.attributes?.price
                                )}{" "}
                                per item
                              </span>
                            )}
                          </div>

                          <div className="flex flex-col w-2/12 text-right">
                            {item?.attributes?.notes && (
                              <span className="font-inter-normal text-[14px]">
                                {item?.attributes?.notes}
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <>
      <div
        className="flex flex-col p-10 pb-0 bg-white min-h-screen md:pt-8 mx-auto"
        style={{ maxWidth: "1440px" }}
      >
        {order ? (
          <>
            <div className="mb-5">
              <button
                className="flex items-center btn-style"
                onClick={() => navigate("/meal-management")}
              >
                <div className="flex" style={{ width: 16, height: 16 }}>
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    size="1x"
                    className="icon"
                  />
                </div>

                <span className="text-md font-inter-medium ml-2">
                  Back to Orders
                </span>
              </button>
            </div>

            {order?.attributes?.status !== "completed" && (
              <div className="flex flex-col bg-background rounded-md">
                {showCancelButton() ? (
                  <span className="text-[14px] py-3 px-3 font-inter-regular">
                    Please note that cancellation is only possible until{" "}
                    {getCancellationDeadline()}
                  </span>
                ) : (
                  <>
                    {order?.attributes?.dropoff_task_eta && (
                      <div className="p-[12px] d-row items-center rounded-lg">
                        <span class="relative flex h-3 w-3">
                          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                          <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                        </span>

                        <span className="px-3 font-inter-normal text-[16px]">
                          {getETAString()}.
                        </span>

                        <button className="text-catering font-inter-medium text-[16px]">
                          Contact us
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}

            {order?.attributes?.status == "cancelled" && (
              <div className="flex flex-row items-center px-3 bg-background rounded-md">
                <span class="relative flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-100"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
                </span>

                <span className="text-[14px] py-3 pl-3 font-inter-medium">
                  This order has been successfully cancelled.
                </span>
                <button className="text-catering ml-2 font-inter-medium text-[16px]">
                  View all orders
                </button>
              </div>
            )}

            {order?.attributes?.status == "completed" && (
              <div className="flex flex-row items-center px-3 bg-background rounded-md">
                <span class="relative flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-100"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                </span>

                <span className="text-[14px] py-3 pl-3 font-inter-medium">
                  Your order is complete.
                </span>
              </div>
            )}

            <div className="d-col border border-[#DEE2E6] border-1 rounded-lg p-[12px] mt-[32px]">
              <div className="flex flex-row justify-between items-center">
                <h1 className="font-inter-semibold text-[24px] text-[#2B313C]">
                  Your Catering order from {order?.attributes?.restaurant_name}
                </h1>

                <div>
                  <button
                    disabled={!showCancelButton()}
                    className={`mr-3 btn-outline ${
                      !showCancelButton() && "opacity-20"
                    }`}
                    onClick={() => setCancelOrderAlert(order)}
                  >
                    <span className="text-hh-accent-light text-[15px] font-inter-medium">
                      Cancel Order
                    </span>
                  </button>

                  {order?.attributes?.status !== "cancelled" && (
                    <button
                      className="btn-purple bg-[#575BE8]"
                      onClick={() =>
                        store.mealManagementStore?.generatePDF(slug)
                      }
                    >
                      Download PDF invoice
                    </button>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-4 gap-4 my-[24px] rounded-lg">
                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Name
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {order?.attributes?.user_name}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Delivery date:
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {order?.attributes?.order_date + " "}
                    {getTime()}
                  </span>
                </div>

                {/* <div className="flex flex-col">
                  <span className="text-[14px] text-black-light">
                    {order?.attributes?.status === "completed"
                      ? "Delivered at:"
                      : order?.attributes?.dropoff_task_eta
                      ? "ETA:"
                      : "Delivery time:"}
                  </span>

                  <span className="font-inter-medium text-[14px] text-black-light mt-1 overflow-hidden overflow-ellipsis">
                    {order?.attributes?.status === "completed"
                      ? getTime()
                      : order?.attributes?.dropoff_task_eta
                      ? getETA()
                      : getTime()}
                  </span>
                </div> */}

                <div className="flex flex-col">
                  <div className="d-row">
                    <span className="text-[14px] font-inter-semibold text-black-light">
                      Delivery window
                    </span>
                    <Tooltip
                      grayIcon
                      tooltipText={
                        "Delivery windows are set to allow for 15 minutes before and after your requested delivery time."
                      }
                      id="delivery-tooltip-time"
                      backgroundColor={"white"}
                      textColor="black"
                    />
                  </div>

                  <span className="font-inter-medium text-[14px] text-black-light mt-1 overflow-hidden overflow-ellipsis">
                    {dateTime.getDeliveryWindow(
                      order?.attributes.delivery_time,
                      order?.attributes?.order_date
                    )}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Point of contact
                  </span>
                  <span className="text-[16px] font-inter-medium">
                    {order.attributes?.delivery_details?.attributes
                      ?.delivery_contact_name + " "}
                  </span>

                  <span className="text-[14px] font-inter-medium">
                    {
                      order.attributes?.delivery_details?.attributes
                        ?.delivery_contact_phone
                    }
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Delivery address
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {formattedAddres(
                      order?.attributes?.delivery_details?.attributes
                    )}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Billing address
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {order.attributes?.location_billing_account?.address}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Delivery instructions
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {
                      order?.attributes?.delivery_details?.attributes
                        ?.delivery_instructions
                    }
                  </span>
                </div>
              </div>
            </div>

            <div className="flex mt-[32px] flex-col md:flex-row md:items-start rounded-3xl px-2 sm:px-5 md:px-0 md:border-0">
              <div className="flex flex-col w-full md:w-3/5 md:rounded-3xl md:mr-3">
                <SearchBar width="w-full mb-[12px]" placeholderValue="Search" />
                <div className="hidden md:flex flex-col">{orderDetails}</div>
              </div>

              <div className="flex flex-col w-full md:w-2/5 mt-5 md:mt-0 p-5 py-7 border border-background rounded-3xl">
                <button
                  onClick={() => {
                    window.location.href = `${
                      process.env.REACT_APP_CATERING_APP_LINK
                    }/restaurant/${
                      order?.attributes?.menu_code
                    }/${changeSpacesToHyphens(
                      order?.attributes?.restaurant_name
                    )}`;
                  }}
                >
                  <a className="text-md2 d-row items-center font-inter-medium mt-5">
                    {order?.attributes?.restaurant_name}
                    <div className="flex" style={{ width: 16, height: 16 }}>
                      <FontAwesomeIcon
                        icon={faAngleLeft}
                        size="1x"
                        className="icon rotate-180 ml-2"
                      />
                    </div>
                  </a>
                </button>

                <div className="mt-3">
                  <div className="flex w-full items-center justify-between mb-2">
                    <span className="text-md">Items</span>

                    <span className="text-md">
                      {priceFormatter(order?.attributes?.subtotal)}
                    </span>
                  </div>

                  <div className="flex w-full items-center justify-between mb-2">
                    <div className="flex items-center">
                      <span className="text-md">Delivery</span>
                      <Tooltip
                        grayIcon
                        tooltipText={
                          "Your delivery cost is dependent on restaurant and proximity to your delivery address"
                        }
                        id="delivery-tooltip-order"
                        backgroundColor={"white"}
                        textColor="black"
                        place={"bottom"}
                      />
                    </div>

                    <span className="text-md">
                      {priceFormatter(order?.attributes?.delivery_fee)}
                    </span>
                  </div>

                  <div className="flex w-full items-center justify-between mb-2">
                    <div className="flex items-center">
                      <span className="text-[16px] font-inter-normal">
                        Service Fee
                      </span>
                      <Tooltip
                        grayIcon
                        tooltipText={
                          "A service fee of seven percent of food total charges is inclusive of all hungerhub fees"
                        }
                        id="service-tooltip-order"
                        backgroundColor={"white"}
                        textColor="black"
                        place={"bottom"}
                      />
                    </div>

                    <span className="text-md">
                      {priceFormatter(order?.attributes?.service_fee)}
                    </span>
                  </div>

                  <div className="flex w-full items-center justify-between mb-2">
                    <span className="text-md">Tax</span>

                    <span className="text-md">
                      {priceFormatter(order?.attributes?.hst)}
                    </span>
                  </div>

                  <div className="flex w-full items-center justify-between mb-2">
                    <span className="text-md">Tip</span>

                    <span className="text-md">
                      {priceFormatter(order?.attributes?.tip)}
                    </span>
                  </div>

                  <hr className="my-5 mb-5" />

                  <div className="flex w-full items-center justify-between">
                    <span className="text-md">Total</span>

                    <span className="text-md">
                      {priceFormatter(order?.attributes?.grand_total)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="sm:flex flex-col my-4 md:hidden">
                {orderDetails}
              </div>

              <ConfirmationModal
                visible={cancelOrderAlert}
                setModal={setCancelOrderAlert}
                title={"Order cancellation"}
                body={`Are you sure you want to cancel this order?`}
                rightButtonText={"Cancel"}
                onClickRightButton={onCancelOrder}
                leftButtonText={"Go Back"}
              />
            </div>
          </>
        ) : (
          <span className="my-5 text-center text-md">Order doesn't exists</span>
        )}
      </div>
    </>
  );
}

export default observer(CateringOrderDetail);
